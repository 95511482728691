import {AnimatePresence, m} from 'framer-motion';
import {Fragment} from 'react';
import {opacityAnimation} from '@common/ui/animation/opacity-animation';
import {Skeleton} from '@common/ui/skeleton/skeleton';
import {useProducts} from '@common/billing/pricing-table/use-products';
import {Product} from '@common/billing/product';
import {
  findBestPrice,
  UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';
import {useAuth} from '@common/auth/use-auth';
import clsx from 'clsx';
import {Chip} from '@common/ui/forms/input-field/chip-field/chip';
import {Trans} from '@common/i18n/trans';
import {FormattedPrice} from '@common/i18n/formatted-price';
import {Button} from '@common/ui/buttons/button';
import {Link} from 'react-router-dom';
import {setInLocalStorage} from '@common/utils/hooks/local-storage';
import {ProductFeatureList} from '@common/billing/pricing-table/product-feature-list';

interface SinglePlanPricingTableProps {
  selectedCycle: UpsellBillingCycle;
  className?: string;
  productLoader?: string;
  targetId: string;
}
export function SinglePlanPricingTable({
  selectedCycle,
  className,
  productLoader,
  targetId,
}: SinglePlanPricingTableProps) {
  const query = useProducts(productLoader);
  return (
    <div
      className={clsx(
        'flex flex-col items-stretch gap-24 overflow-x-auto overflow-y-visible pb-20 md:flex-row md:justify-center single-plan-center',
        className,
      )}
    >
      <AnimatePresence initial={false} mode="wait">
        {query.data ? (
          <SinglePlan
            key="single-plan"
            plans={query.data.products}
            selectedPeriod={selectedCycle}
            targetId={targetId}
          />
        ) : (
          <SkeletonLoader key="skeleton-loader" />
        )}
      </AnimatePresence>
    </div>
  );
}

interface SinglePlanProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
  targetId: string;
}
function SinglePlan({plans, selectedPeriod, targetId}: SinglePlanProps) {
  const {isLoggedIn, isSubscribed} = useAuth();
  const targetIdNumber = Number(targetId);
  const plan = plans.find(plan => plan.id === targetIdNumber);

  if (!plan) {
    return <div>Plan not found</div>;
  }

  const price = findBestPrice(selectedPeriod, plan.prices);
  const trial = plan.kidson_trial_enable;
  const trialAmount = parseFloat(plan.kidson_trial_amount ?? '0').toFixed(2);

  let upgradeRoute;
  if (!isLoggedIn) {
    upgradeRoute = '/register?redirectFrom=pricing';
  }
  if (isSubscribed) {
    upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
  }
  if (isLoggedIn && !plan.free) {
    upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
  }

  return (
    <m.div
      key={plan.id}
      {...opacityAnimation}
      className="w-full rounded-panel border bg px-28 py-28 shadow-lg md:min-w-240 md:max-w-350"
    >
      <div className="mb-32">
        <Chip
          radius="rounded"
          size="sm"
          className={clsx('mb-20 w-min', !plan.recommended && 'invisible')}
        >
          <Trans message="Most popular" />
        </Chip>
        <div className="mb-12 text-xl font-semibold">
          <Trans message={plan.name} />
        </div>
        <div className="text-sm text-muted">
          <Trans message={plan.description} />
        </div>
      </div>
      <div>
        {trial === "0" ? (
          price ? (
            <FormattedPrice
              priceClassName="font-bold text-4xl"
              periodClassName="text-muted text-xs"
              variant="separateLine"
              price={price}
            />
          ) : (
            <div className="text-4xl font-bold">
              <Trans message="Free" />
            </div>
          )
        ) : (
          <>
            <div className="text-sm text-muted">
              <Trans message="Pirmās" /> {plan.kidson_trial_period} <Trans message="dienas" />
            </div>
            <div className="mb-12 font-bold text-4xl trialAmount">
              <Trans message={`${trialAmount} €`} />
            </div>
            <div className="text-sm text-muted">
              <Trans message="Pēc tam" />
            </div>
            <FormattedPrice
              priceClassName="text-xl font-semibold"
              periodClassName="text-muted text-xs"
              variant="separateLine"
              price={price}
            />
          </>
        )}
        <div className="mt-60">
          <Button
            variant={plan.recommended ? 'flat' : 'outline'}
            color="primary"
            className="w-full"
            size="md"
            elementType={upgradeRoute ? Link : undefined}
            disabled={!upgradeRoute}
            onClick={() => {
              if (isLoggedIn || !price || !plan) return;
              setInLocalStorage('be.onboarding.selected', {
                productId: plan.id,
                priceId: price.id,
              });
            }}
            to={upgradeRoute}
          >
            {plan.free ? (
              <Trans message="Get started" />
            ) : (
              <Trans message="Upgrade" />
            )}
          </Button>
        </div>
        <ProductFeatureList product={plan} />
      </div>
    </m.div>
  );
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div
      {...opacityAnimation}
      className="w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350"
    >
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="mb-40 h-30" />
      <Skeleton className="mb-40 h-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
