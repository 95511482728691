import {Fragment} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';

export function AppPromoPage() {
  return (
    <Fragment>
      <DefaultMetaTags />
      <HeroHeader />
    </Fragment>
  );
}

function HeroHeader() {
  let overlayBackground = undefined;
  overlayBackground = `linear-gradient(45deg, #F56531 0%, #FFA94C 100%)`;

  return (
    <header className="relative isolate mb-14 overflow-hidden md:mb-60 appPromoPage">
      <div className="promoPageHeader">
        <img className="promoPageLogo" src="images/promo-image.png" alt="KidsOn Logo"/>
      </div>
      <div
        className="absolute z-10 h-full w-full bg-[rgb(37,99,235)]"
        style={{background: overlayBackground}}
      />
      <div className="relative z-30 h-full promoPage text-dark">
        <div className="promoPageImageD">
          <div className="images-wrapper">
            <img className="image-item notes" src="images/notes.svg" alt="Notes" />
            <img className="image-item phone-1" src="images/smartphone1.png" alt="Telefons 1" />
            <img className="image-item phone-2" src="images/smartphone2.png" alt="Telefons 2" />
          </div>
        </div>
        <div className="promoPageText">
          <h1 className="promoPageText1" >
            <p><b>KidsOn</b> mūzikas un izklaides platforma ar saturu bērniem latviešu valodā.</p>
          </h1>
          <h1 className="promoPageText2" >
            <p>Tagad arī aplikācijā!</p>
          </h1>
          <div className="promoPageText3" >
            <p>Lejupielādē to App Store un Google Play veikalos:</p>
          </div>
          <div className="promoPageButtons">
            <a href="https://play.google.com/store/apps/details?id=com.flutter.kidson"><img className="promoPageGoogle" src="images/googleplay.svg" alt="Google Play"/></a> 
            <a href="https://apps.apple.com/lv/app/kidson-lv/id6740343865"><img className="promoPageApple" src="images/appstore.svg" alt="App Store"/></a>
          </div>
        </div>
        <div className="promoPageImageM">
          <div className="images-wrapper">
            <img className="image-item phone-1" src="images/smartphone1.png" alt="Telefons 1" />
            <img className="image-item notes" src="images/notes.svg" alt="Notes" />
            <img className="image-item phone-2" src="images/smartphone2.png" alt="Telefons 2" />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 z-20 h-[6vw] w-full translate-y-1/2 -skew-y-3 transform bg"></div>
    </header>
  );
}